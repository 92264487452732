// --------------------
// styles
// --------------------
import 'virtual:windi.css'; // 先做引用，避免預檢樣式蓋掉自訂樣式
import '/css/app.css';
import '../../node_modules/@fortawesome/fontawesome-free/css/all.min.css';

// --------------------
// Components
// --------------------
import '../components/heading-1.js';

// --------------------
// Vendor
// --------------------
// import { createApp } from 'vue';
// import { defineCustomElement } from 'vue';

import AOS from 'aos';
import 'aos/dist/aos.css';

setTimeout(() => {
  AOS.init();
}, 180);

// --------------------
// Vue custom elements (無法吃全域 css，待處理)
// --------------------
// import Heading1 from './components/Heading1.ce.vue';
// const Heading1Element = defineCustomElement(Heading1);
// customElements.define('heading-1', Heading1Element);

// --------------------
// Vue App
// --------------------
// const app = createApp(App).mount('#app');

// --------------------
// Contact Form Submit
// --------------------
let form = document.getElementById('contact-form');
if (form) {
  form.addEventListener('submit', (e) => {
    e.preventDefault();

    let btn = e.target.querySelector('button[type=submit]');
    let btnText = btn.innerText;
    btn.innerText = '傳送中 ..';
    btn.setAttribute('disabled', true);

    let data = new FormData(form);

    fetch('/mail.php', {
      method: 'POST',
      body: data
    })
      .then((response) => {
        console.log(response);

        if (! response.ok) {
          return Promise.reject(response.status);
        }

        return Promise.resolve(response);
      })
      .then((response) => {
        // Pixel
        fbq('track', 'Contact');

        btn.innerText = '訊息已送出';
        alert('訊息已送出');
      })
      .catch((status) => {
        let message = status === 500 ? '系統發生錯誤' : '欄位填寫不全或格式錯誤，請重新輸入';

        btn.removeAttribute('disabled');
        btn.innerText = btnText;
        alert(message);
      });
  });
}
